import React from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

function ToastComponent(type, text) {
  const ToastContent = () => {
    const { t } = useTranslation();
    return <span>{t(text)}</span>;
  };

  return toast[type](<ToastContent />, {
    position: "top-right",
  });
}

export default ToastComponent;
