import Axios from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Drawer,
  ButtonToolbar,
  Button,
  Placeholder,
  SelectPicker,
  Input,
  Stack,
  useToaster,
  Whisper,
  Tooltip,
} from "rsuite";
import { API_URL } from "utils/settings";
import { toast } from "react-toastify";
import ToastComponent from "components/ToastComponent";
import "react-toastify/dist/ReactToastify.css";

function AddSmsModal(props) {
  const {
    open,
    setOpen,
    providers,
    countries,
    services,
    callback,
    type,
    defaultValue,
    setLoading,
  } = props;
  const [data, setData] = useState({
    template: type == "edit" ? defaultValue.template : null,
    service: type == "edit" ? defaultValue.service : null,
    country: type == "edit" ? defaultValue.country : null,
    provider: type == "edit" ? defaultValue.provider : null,
    example: type == "edit" ? defaultValue.example : null,
  });
  const toaster = useToaster();
  const { t } = useTranslation();
  // const handleChange = (val, name) => {
  //   setData({ ...data, [name]: val });
  // };
  const handleSubmit = () => {
    if (type == "add") {

      if (!data.template || !data.country || !data.service || !data.provider) {
        ToastComponent("error", t("empty"), toaster);
        return;
      }
      setLoading(true);
      Axios.post(`${API_URL}/admin/sms/template`, data)
        .then(function (response) {
          if (response.data) {
            callback();
            setOpen(false);
            setData({
              template: "",
              service: null,
              country: null,
              provider: null,
              example: "",
            });
          } else {
          }
        })
        .catch((error) => {
          setLoading(false);
        });
    } else if (type == "edit") {
      setLoading(true);
      Axios.put(`${API_URL}/admin/sms/template/${defaultValue.id}`, {
        template: data.template,
        service: data.service,
        country: data.country,
        provider: data.provider,
        example: data.example,
      })
        .then(function (response) {
          callback();
          setOpen(false);
          setData({
            template: "",
            service: null,
            country: null,
            provider: null,
            example: "",
          });
        })
        .catch((error) => {
          setLoading(false);
          // setLoading(false);
        });
    }
  };
  return (
    <Drawer open={open} onClose={() => setOpen(false)}>
      <Drawer.Header>
        <Drawer.Actions>
          <Button onClick={() => setOpen(false)}>{t("common.cancel")}</Button>
          <Button
            onClick={() => {
              handleSubmit();
            }}
            appearance="primary"
          >
            {t("confirm.confirm")}
          </Button>
        </Drawer.Actions>
      </Drawer.Header>
      <Drawer.Body>
        <form>
          <Whisper
            trigger="hover"
            placement="left"
            speaker={<Tooltip>{t("tooltip.required")}</Tooltip>}
          >
            <Input
              value={data?.template}
              placeholder={t("table.regex") + "*"}
              onChange={(val) => setData({ ...data, template: val })}
            />
          </Whisper>
          <br />
          <Whisper
            trigger="hover"
            placement="left"
            speaker={<Tooltip>{t("tooltip.required")}</Tooltip>}
          >
            <div>
              <SelectPicker
                data={providers?.map((el) => {
                  return {
                    value: el.uid,
                    label: el.uid,
                  };
                })}
                onChange={(val) => setData({ ...data, provider: val })}
                defaultValue={data.provider}
                style={{ width: "100%" }}
                disabled={type == "edit"}
                placeholder={t("table.provider") + "*"}
              />
            </div>
          </Whisper>
          <br />
          <Whisper
            trigger="hover"
            placement="left"
            speaker={<Tooltip>{t("tooltip.required")}</Tooltip>}
          >
            <div>
              <SelectPicker
                data={services?.map((el) => {
                  return {
                    value: el.uid,
                    label: el.name,
                  };
                })}
                onChange={(val) => setData({ ...data, service: val })}
                disabled={type == "edit"}
                style={{ width: "100%" }}
                defaultValue={data.service}
                placeholder={t("table.service") + "*"}
              />
            </div>
          </Whisper>
          <br />
          <Whisper
            trigger="hover"
            placement="left"
            speaker={<Tooltip>{t("tooltip.required")}</Tooltip>}
          >
            <div>
              <SelectPicker
                data={countries.map((el) => {
                  return {
                    value: el.id,
                    label: (
                      <div className="country-block">
                        <div className={`flag flag-${el.id}`}></div>
                        <span>{t(`country.${el.id}`)}</span>
                      </div>
                    ),
                  };
                })}
                disabled={type == "edit"}
                onChange={(val) => setData({ ...data, country: val })}
                style={{ width: "100%" }}
                defaultValue={data.country}
                placeholder={t("table.country") + "*"}
              />
            </div>
          </Whisper>
          <br />

          <Input
            as="textarea"
            rows={3}
            placeholder={t("table.example")}
            defaultValue={data.example}
            onChange={(val) => setData({ ...data, example: val })}
          />
        </form>
      </Drawer.Body>
    </Drawer>
  );
}

export default AddSmsModal;
